<template>
  <div class="box-container">
    <div class="absolute cursor-pointer d-print-none top-2 right-2" @click="handleBack">
      <div>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mx-1"
        >
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
    <div class="marin-print"></div>
    <div class="p-4 cs-border">
      <div class="font-style mb-3">
        <div class="flex justify-between fs-16">
          <div class="text-left" style="max-width: 200px">
            <!-- <div>{{ org.aic }}</div>
            <div>{{ org.org_name }}</div>
            <div class="fs-13 capitalize">{{ org.name }}</div>-->
            <img :src="appUtils.getImageURL(org && org.logo)" alt />
          </div>
          <div class="text-right normal-case" style="max-width: 400px">
            <!-- <div>
              <span class="mr-1">{{ $t("Mã BN: ") }}</span>
              <span>{{ serviceDetail.pid }}</span>
            </div>
            <div>
              <span class="mr-1">{{ $t("Ký hiệu: ") }}</span>
              <span></span>
            </div>
            <div>
              <span class="mr-1">{{ $t("Số phiếu: ") }}</span>
              <span>{{ serviceDetail.number_code }}</span>
            </div>-->
            <p class="mb-0 fs-18 font-bold uppercase" v-if="org.name">{{ org.name }}</p>
            <p class="mb-0 font-normal" v-if="org.address">{{ org.address }}</p>
            <p class="mb-0 font-normal" v-if="org.phone">{{ org.phone }}</p>
          </div>
        </div>
        <div class="text-center uppercase fs-24 my-3">{{ $t("Phiếu thu") }}</div>
      </div>

      <div>
        <div class="flex item-center gap-2">
          <div class="flex-1">
            <span class="mr-2">{{ $t("Khách hàng:") }}</span>
            <strong>{{ serviceDetail.person_name }}</strong>
          </div>
          <div class="flex-1 flex gap-4">
            <div>
              <span class="mr-2">{{ $t("Mã phiếu thu:") }}</span>
              <span>{{ serviceDetail.number_code }}</span>
            </div>
            <!-- <div>
              <span class="mr-2">{{ $t("Năm sinh:") }}</span>
              <span>{{ serviceDetail.person_yob }}</span>
            </div>-->
          </div>
        </div>
        <!-- <div class="flex item-center gap-2">
          <div class="flex-1">
            <span class="mr-2">{{ $t("Đối tượng:") }}</span>
            <span></span>
          </div>
          <div class="flex-1">
            <span class="mr-2">{{ $t("Số thẻ:") }}</span>
            <span></span>
          </div>
        </div>
        <div>
          <span class="mr-2">{{ $t("Địa chỉ:") }}</span>
          <span>{{ serviceDetail.address || "" }}</span>
        </div>
        <div>
          <span class="mr-2">{{ $t("Khoa,phòng:") }}</span>
          <span>{{ serviceDetail.workspace && serviceDetail.workspace.name }}</span>
        </div>
        <div class="flex item-center gap-2">
          <div class="flex-1">
            <span class="mr-2">{{ $t("Chẩn đoán:") }}</span>
            <span>{{ serviceDetail.diagnostic }}</span>
          </div>
          <div class="flex-1 text-right">
            <strong class>{{ $t("ĐVT: đồng") }}</strong>
            <span></span>
          </div>
        </div>-->
        <div class="flex item-center gap-2">
          <div class="flex-1">
            <span class="mr-2">{{ $t("Ngày lập phiếu:") }}</span>
            <span>{{ getHMDMYVN(serviceDetail.created_at) }}</span>
          </div>
        </div>
      </div>
      <table class="mb-3 mt-2">
        <tr class="font-style">
          <th width="4%">{{ $t("STT") }}</th>
          <th width="45%">{{ $t("Sản phẩm / Dịch vụ") }}</th>
          <th width="5%">{{ $t("ĐVT") }}</th>
          <th width="6%">{{ $t("SL") }}</th>
          <th width="20%">{{ $t("Đơn giá") }}</th>
          <th width="20%">{{ $t("Thành tiền") }}</th>
        </tr>
        <tr
          class="table-row"
          v-for="(item, i) in indicationServices"
          :key="i + 'indicationService'"
        >
          <td>{{ i + 1 }}</td>
          <td class="text-left">{{ getServiceName(item) }}</td>
          <td></td>
          <td>{{ item.qty }}</td>
          <td class="text-right">{{ getPrice(item) }}</td>
          <td class="text-right">{{ appUtils.numberFormat(item.transaction_amount || 0) }}</td>
        </tr>

        <tr class="table-row">
          <td colspan="5" class="text-right font-bold">{{ $t("Tổng tiền") }}</td>
          <td
            colspan="1"
            class="text-right font-bold"
          >{{ appUtils.numberFormat(serviceDetail.amount || 0) }}</td>
        </tr>
        <tr class="table-row">
          <td colspan="5" class="text-right">{{ $t("Khuyến mại") }}</td>
          <td colspan="1" class="text-right"></td>
        </tr>
        <tr class="table-row">
          <td colspan="5" class="text-right">{{ $t("Khách trả") }}</td>
          <td colspan="1" class="text-right"></td>
        </tr>
        <tr class="table-row">
          <td colspan="5" class="text-right">{{ $t("Trả lại") }}</td>
          <td colspan="1" class="text-right"></td>
        </tr>
      </table>
      <div class="flex justify-between">
        <div>
          <strong>{{ $t("Bằng chữ: ") }}</strong>
          <span class="cs-text inline-block">
            <p>{{ appUtils.numberToWords(serviceDetail.amount || 0) }}</p>
          </span>
        </div>
        <div>
          <strong>{{ $t("Trạng thái: ") }}</strong>
          <span class="cs-text inline-block">
            <p>{{ paymentStatus }}</p>
          </span>
        </div>
      </div>
      <div class="box-footer mt-4">
        <div class="text-center flex-1">
          <!-- <span>{{ getHMDMYVN(serviceDetail.created_at) }}</span> -->
          <div class="font-style">{{ $t("Người nộp tiền") }}</div>
          <span class="italic">{{ $t("(Ký, ghi rõ họ tên)") }}</span>
          <div class="mt-16">
            <div class="sign-text"></div>
          </div>
        </div>
        <div class="text-center flex-1">
          <!-- <span>{{ getHMDMYVN(serviceDetail.created_at) }}</span> -->
          <div class="font-style">{{ $t("Người thu tiền") }}</div>
          <span class="italic">{{ $t("(Ký, ghi rõ họ tên)") }}</span>
          <div class="mt-16">
            <!-- <div class="sign-text">{{ serviceDetail.created_by && serviceDetail.created_by.name }}</div> -->
            <div class="sign-text"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end my-5 d-print-none">
      <div class="d-flex">
        <button class="btn bg-pri bd-pri text-white ml-3" @click="print">{{ $t("In phiếu") }}</button>
      </div>
    </div>
  </div>
</template>
    
    <script>
import moment from 'moment'
import appUtils from '@/utils/appUtils'

export default {
  name: 'IndicationDetail',
  data () {
    return {
      serviceDetail: {},
      indicationServices: [],
      appUtils
    }
  },
  watch: {},
  computed: {
    DoctorRequest () {
      return this.$rf.getRequest('DoctorRequest')
    },
    paymentStatus () {
      return this.serviceDetail?.service_payment_transaction?.status === 2
        ? 'Đã thanh toán'
        : 'Chưa thanh toán'
    },
    date () {
      const dateQuery = this.$route.query.date
      const date = moment(dateQuery)
      return date.isValid()
        ? `${date.format('dddd')} ngày ${date.format('DD.MM.YYYY')}`
        : date
    },
    org () {
      const org = this.$globalClinic
      return {
        name: org?.name,
        org_name: org?.organization?.name,
        aic: org?.organization?.authority_in_charge,
        address: org.address,
        phone: org.phone,
        logo: org?.organization?.logo
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    formatYear (date) {
      return window.moment(date).format('YYYY')
    },
    async fetchData () {
      try {
        const serviceID = this.$route.params.id

        const res = await this.DoctorRequest.getDetailServiceTransactionSlip(
          serviceID
        )
        this.serviceDetail = res.data || {}
        this.indicationServices = res.data?.service_transactions || []
      } catch (error) {
        console.log(error)
      }
    },
    handleBack () {
      if (this.$route.query.type === 'payment-management') {
        this.$router.go(-1)
      } else {
        this.$router
          .replace({
            name: 'PatientVisitDetail',
            params: {
              id: this.$route.query.pv_id
            }
          })
          .catch(() => {})
      }
    },
    print () {
      const tempTitle = document.title
      document.title = `Danh sach dich vu_${moment().format('DDMMYYYY')}.pdf`
      window.print()
      document.title = tempTitle
    },

    getHMDMYVN (date) {
      const string = this.moment(date).locale(this.$lang)
      if (this.$lang === 'vi') {
        let hm = window.moment(date).format('HH:mm')
        const d = string.clone().format('DD')
        const m = string.clone().format('MM')
        const y = string.clone().format('YYYY')
        return `${hm} - ${d}/${m}/${y}`
      } else {
        return string.format('LLL')
      }
    },
    convertHtmlToPlainText (html) {
      // Create a new div element
      if (!html) return ''
      let tempDivElement = document.createElement('div')
      // Set the HTML content with the given value
      tempDivElement.innerHTML = html
      // Retrieve the text property of the element
      return tempDivElement.textContent || tempDivElement.innerText || ''
    },
    getPid (data) {
      return (
        data?.medical_record?.person_ref_id ||
        (data?.medical_record?.person && data?.medical_record?.person?.pid) ||
        data?.operation?.code ||
        data?.concluding?.profile_code
      )
    },
    getDiagnose (item) {
      return (
        (item.operation && item?.operation?.diagnostic) ||
        (item.multilang && item?.multilang[0]?.patient_diagnostic) ||
        item?.patient_diagnostic
      )
    },
    getEMRCode (item) {
      return item?.medical_record?.ref_id || item?.person?.doc_no
    },
    getServiceName (item) {
      if (item?.type_service === 1) {
        return item.service_catalog && item.service_catalog.name
      } else {
        return item.local_service && item.local_service.local_name
      }
    },
    getPrice (item) {
      if (item?.type_service === 1) {
        return (
          item.service_catalog &&
          appUtils.numberFormat(item.service_catalog.price || 0)
        )
      } else {
        return (
          item.local_service &&
          appUtils.numberFormat(item.local_service.local_price || 0)
        )
      }
    }
  }
}
</script>
    
    <style scoped lang="scss">
.box-container {
  color: black;
  max-width: 1125px;
  padding: 0 20px;
  margin: auto;
  ::v-deep * {
    font-family: 'Times New Roman', 'Times', 'Tinos', serif !important;
  }
}
.box-footer {
  display: flex;
  justify-content: end;
  margin-right: 80px;
}
.font-style {
  color: black;
  font-weight: bold;
  font-size: 14px;
}
table,
th,
td {
  color: black;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}
table {
  width: 100%;
}
.table-row > td {
  padding: 0 5px;
}
.table-row,
.sign-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.sign-input {
  outline: 0;
  border-width: 0 0 1px;
  text-align: center;
}

.marin-print {
  margin-top: 60px;
}

.cs-note {
  display: none;
}

.cs-text {
  text-transform: lowercase;

  p::first-letter {
    text-transform: capitalize;
  }
}

.cs-border {
  border: 1px solid rgba(101, 109, 119, 0.16);
}

@media print {
  #BrowserPrintDefaults {
    display: none;
  }

  thead,
  tfoot {
    display: none !important;
  }

  @page {
    size: auto A4 landscape;
    margin: 0;
    margin-bottom: 10mm;
    margin-top: 10mm;
  }

  // .container,
  // .box-container {
  //   max-width: unset;
  //   width: 1150px;
  // }
  .sign-input {
    display: none;
  }
  .sign-text {
    display: block;
    font-weight: bold;
  }
  .word-break {
    word-break: break-all;
  }
  .marin-print {
    display: none;
  }

  .cs-note {
    display: block;
  }

  .cs-border {
    padding-top: 0px !important;

    border: none !important;
  }
}

::v-deep {
  .cs-input {
    input {
      background-color: #dae8fd !important;
      padding: 4px;
    }
  }
}
</style>
    